import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routeOptions = [
    {
        path: "/",
        name: "Dashboard",
        meta: { requiresAuth: true }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        meta: { requiresAuth: true }
    },
    /*{
        path: "/information",
        name: "Information",
        meta: { requiresAuth: true }
    },
    {
        path: "/about",
        name: "About",
        meta: { requiresAuth: true }
    }, */
    {
        path: "/user/:id",
        name: "User",
        meta: { requiresAuth: true }
    },
    /*{
        path: "/video-chat",
        name: "VideoChat",
        meta: { requiresAuth: true }
    },
    */
    {
        path: "/settings",
        name: "Settings",
        meta: { requiresAuth: true }
    },
    {
        path: "/places",
        name: "Places",
        meta: { requiresAuth: true }
    },
    {
        path: "/places/search",
        name: "SearchPlaces",
        meta: { requiresAuth: true }
    },
    /**

    {
        path: "/products",
        name: "Products",
        meta: { requiresAuth: true }
    },

    {
        path: "/channels",
        name: "Channels",
        meta: { requiresAuth: true }
    },
    {
        path: "/category",
        name: "Category",
        meta: { requiresAuth: true }
    },
    {
        path: "/landing",
        name: "Landing",
        meta: { requiresVisitor: true }
    }, */
    {
        path: "/login",
        name: "Login",
        meta: { requiresVisitor: true }
    },
    {
        path: "/register",
        name: "Register",
        meta: { requiresVisitor: true }
    },
    {
        path: "/email/verify/:id/:token",
        name: "VerifyUser",
        meta: { requiresVisitor: true }
    },
    {
        path: "/phone/verify/:id/:token",
        name: "VerifyUserByPhone",
        meta: { requiresVisitor: true }
    },
    {
        path: "/username/resend",
        name: "ResendVerification",
        meta: { requiresVisitor: true }
    },
    {
        path: "/password-forgot",
        name: "PasswordForgot",
        meta: { requiresVisitor: true }
    },
    {
        path: "/password/reset/:token",
        name: "PasswordReset",
        meta: { requiresVisitor: true }
    },
    /*
    {
        path: "/studio",
        name: "Studio",
        meta: { requiresVisitor: true }
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        meta: { requiresAuth: true }
    }, */
    {
        path: "/new-streaming",
        name: "NewStreaming",
        meta: { requiresAuth: true }
    },
    {
        path: "/live-streaming/:id",
        name: "LiveStreaming",
        meta: { requiresAuth: true }

    },
    {
        path: "/live-streamings/:id",
        name: "LiveStreamings",
        meta: { requiresAuth: true }

    },

    {
        path: "/streaming-users",
        name: "StreamingUsers",
        meta: { requiresAuth: true }
    },
    {
        path: "/socket.io",
        name: "socket.io",
        meta: { requiresAuth: false }
    }
];

const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () =>
            import(
                /* webpackChunkName: "[request]" */ `../views/${route.name}.vue`
            )
    };
});

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    base: "/",
    routes
});

function isLoggedIn() {
    return localStorage.getItem("isLoggedIn");
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isLoggedIn()) {
            next({
                name: "Login"
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (isLoggedIn()) {
            next({
                name: "/"
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
