const state = {
    selectedUser: {},
    selectedBottomNavItem: null
};

const getters = {};

const actions = {};

const mutations = {
    updateState(state, payload) {
        for (const key of Object.keys(payload)) {
            state[key] = payload[key];
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
