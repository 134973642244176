import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import vuetify from "./plugins/vuetify";
import moment from "moment";
import { camelCase, startCase } from "lodash";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSimpleAlert from "vue-simple-alert";
//import BootstrapVue from 'boostrap-vue'
//Vue.use(BootstrapVue)
//require("./plugins/laravel-echo");
require("@/assets/sass/main.scss");

Vue.config.productionTip = false;
Vue.filter("ampm", value => moment(String(value)).format("h:mm a"));
Vue.filter("toTitleCase", str => startCase(camelCase(str)));

Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBt8ozi0VE1TZrkLOlF0CpAJyFoYjqTdg4",
    },
    installComponents: true,
  });
Vue.use(VueSimpleAlert);
Vue.component('gmap-cluster', VueGoogleMaps.Cluster);

new Vue({
    router,
    store,
    //vuetify,
    render: h => h(App)
}).$mount("#app");
