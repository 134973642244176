import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-CSRF-TOKEN"] = document.head.querySelector(
    'meta[name="csrf-token"]'
).content;
export default {
    auth: {
        user: () => axios.get("/api/user"),
        getCookie: () => axios.get("/sanctum/csrf-cookie"),
        register: data => axios.post("/register", data),
        login: data => axios.post("/login", data),
        logout: () => axios.post("/logout"),
        verifyUser: params =>
            axios.get(`/api/verify/email/${params.id}/${params.token}`),
        verifyUserByPhone: params =>
            axios.get(`/api/verify/phone/${params.id}/${params.token}`),
        resendVerificationEmail: params =>
            axios.get("/api/verify/email/resend", params),
        resendVerificationUsername: params =>
            axios.get("/api/verify/username/resend", params),
        sendResetPassword: data =>
            axios.post("/api/auth/forgot-password", data),
        verifyPin: data =>
            axios.post("/api/auth/verifyPin", data),

        resetPassword: data => axios.post("/api/auth/reset-password", data),
        updatePassword: data => axios.post("/api/auth/update-password", data)
    },
    user: {
        find: params => axios.get(`/api/users/find`, { params }),
        getAll: params => axios.get(`/api/users`, { params }),
        delete: id => axios.delete(`/api/users/${id}`),
        search: params => axios.get(`/api/users/search`, { params }),
        show: id => axios.get(`/api/users/${id}`),
        update: (id, data) => axios.put(`/api/users/${id}`, data),
        mapView: (id) => axios.get(`/api/users/mapview/${id}`),

        updateProfile: (id, data) =>
            axios.put(`/api/users/${id}/profile`, data),
        videochatAvailable: () =>
            axios.get(`/api/users/videochat-available/random`),
        videochatAvailableUsers: () =>
            axios.get(`/api/users/videochat-available`),
        getVideoStreamingUsers: data =>
            axios.get(`/api/users/video-streaming`, data),
        placeVideoStreamingAvailable: () =>
            axios.get(`/api/users/video-streaming/random`),
        updateVideoStreamingDetails: (id, data) =>
            axios.put(`/api/users/${id}/video-streaming`, data),
        updateToOnline: id => axios.put(`/api/users/${id}/online`),
        updateToOffline: id => axios.put(`/api/users/${id}/offline`),
        uploadAvatar: (id, data) =>
            axios.post(`/api/users/${id}/upload-avatar`, data),
        uploadPlaceAvatar: (id, data) =>
            axios.post(`/api/users/${id}/upload-place-avatar`, data),
        uploadThumbnailVideo: (id, data) =>
            axios.post(`/api/users/${id}/upload-thumbnail-video`, data),
        uploadThumbnailImage: (id, data) =>
            axios.post(`/api/users/${id}/upload-thumbnail-image`, data),
        uploadImage: (id, data) =>
            axios.post(`/api/users/${id}/upload-image`, data),
        deleteImage: (id, data) =>
            axios.delete(`/api/users/${id}/delete-image`, data),
        sendRequest: (id, opponentId) =>
            axios.post(`/api/users/${id}/send-request/${opponentId}`),
        cancelRequest: (id, opponentId) =>
            axios.post(`/api/users/${id}/cancel-request/${opponentId}`),
        like: (id, to) => axios.patch(`/api/users/${id}/like/${to}`),
        dislike: (id, to) => axios.patch(`/api/users/${id}/dislike/${to}`),
        getAllViewers: (id, data) =>
            axios.get(`/api/users/${id}/viewers`, data),

        getAllConnectedViewers:(id, data) => axios.get(`/api/users/${id}/getviewers`, data),
        getRoomID:(id) => axios.get(`/api/users/${id}/getroomid`),
        getStreamVersion:(id)=>axios.get(`/api/users/${id}/getversion`),
        addViewer: (id, viewerUserId, data) =>
            axios.post(`/api/users/${id}/add-viewer/${viewerUserId}`, data),
        removeViewer: (id, viewerUserId) =>
            axios.post(`/api/users/${id}/remove-viewer/${viewerUserId}`),
        createStreamingEvent: (id, data) =>
            axios.post(`/api/users/${id}/streaming`, data),
        submitReview: (id, data) => axios.post(`/api/users/${id}/review`, data),
        getStreamingStart: (id,data) => axios.post(`/api/users/${id}/getStreamingStart`,data),
        streaming: (id,data) => axios.post(`/api/users/streaming/${id}`,data),
        getstreaming: (id,data) => axios.get(`/api/users/getstreaming/${id}`,data),
        updateFavourite: (id,data) => axios.post(`/api/users/${id}/updateFavourite`,data),


    },
    contact: {
        delete: id => axios.delete(`/api/contacts/${id}`)
    },
    profile: {
        getAll: () => axios.get("/api/profiles")
    },
    notification: {
        update: (id, data) => axios.put(`/api/notifications/${id}`, data),
        delete: id => axios.delete(`/api/notifications/${id}`),
        markAsRead: () => axios.patch(`/api/notifications/mark-as-read`)
    },
    message: {
        get: userTwoId => axios.get(`/api/messages/${userTwoId}`),
        update: (userTwoId, data) =>
            axios.patch(`/api/messages/${userTwoId}`, data),
        delete: userTwoId => axios.delete(`/api/messages/${userTwoId}`),
        recent: () => axios.get(`/api/messages/recent`),
        send: (userTwoId, message) =>
            axios.post(`/api/messages/${userTwoId}`, message)
    },
    review: {
        all: () => axios.get("/api/reviews")
    },
    sms: {
        sendVerification: (id, data) =>
            axios.patch(`/api/users/${id}/send-verification/sms`, data),
        verifyPhoneNumber: (id, data) =>
            axios.patch(`/api/users/${id}/verify/phone-number`, {
                input_verification_code: data
            })
    }
};
