<template>
        <router-view :user="user" v-if="user"></router-view>
        <router-view v-else ></router-view>
</template>

<script>
import api from "@/api";
//import SideNavigation from "./components/SideNavigation.vue";
//import SideNavigationAddressBook from "./components/SideNavigationAddressBook.vue";
//import BottomNav from "./components/BottomNav.vue";
//import Chat from "./components/Chat.vue";
import _ from "lodash";
import moment from "moment";
//import Offline from  'v-offline'
export default {
    name: "App",
    props: ["color"],
    computed: {
        currentRoute() {
            return this.$route;
        },

        items() {
            const items = [
                { name: "userMenu", text: this.user.name, icon: "person" },
                {
                    name: "notifications",
                    text: "Notifications",
                    icon: "notification_important"
                },
                { name: "contacts", text: "Contacts", icon: "contacts" },
                {
                    name: "profiles",
                    text: "Profiles",
                    icon: "mdi-account-group"
                },
                {
                    name: "randomChat",
                    text: "Random Chat",
                    icon: "mdi-play-circle-outline"
                },
                { name: "settings", text: "Settings", icon: "settings" },
                { name: "logout", text: "Logout", icon: "mdi-logout" }
            ];
            return items;
        },
        notifications() {
            if (this.user && this.user.notifications) {
                return this.user.notifications.filter(
                    notification => Number(notification.status) === 1
                );
            } else {
                return [];
            }
        },
        computedRecentMessages() {
            return _.pickBy(
                this.recentMessages,
                message => message.id && message.friend
            );
        },
        showNavbar() {
            return !["Login", "Register", "Landing"].includes(
                this.currentRoute.name
            );
        },
        showBottomNav() {
            return !["Login", "Register", "Landing"].includes(
                this.currentRoute.name
            );
        },
        showDashboardBottomNav() {
            return [
                "Dashboard",
                "Information",
                "About",
                "Settings",
                "TermsAndConditions"
            ].includes(this.currentRoute.name);
        },
        showNavbarActions() {
            return ![
                "VerifyUser",
                "ResendVerification",
                "PasswordForgot",
                "PasswordReset"
            ].includes(this.currentRoute.name);
        },
        isProfilePage() {
            return this.$route.name === "Home";
        },
        isPlacePage() {
            return this.$route.name === "Places";
        },
        isProductPage() {
            return this.$route.name === "Products";
        },
        logoWidth() {
            return this.logoSrc() === "/images/logo/net_logo.png" ? 55 : 130;
        }
    },
    components: {
      //  "Offline" :Offline,
       // "side-navigation": SideNavigation,
       // "side-navigation-address-book": SideNavigationAddressBook,
       // chat: Chat,
       // "bottom-nav": BottomNav
    },

    data: () => ({
        currentRouteName:location.pathname.replace(/[0-9]/g, ''),
        item: 1,
        user: {},
        showNotifications: false,
        showAddressBook: false,
        showChatbox: false,
        userTwo: {},
        recentMessages: [],
        message: {},
        newNotification: null,
        navbarIconsPath: {
            notification: "/images/navbar/notifications",
            addressbook: "/images/navbar/addressbook",
            profiles: "/images/navbar/profiles"
        },
        isMounted: false,
        showMoreFooterContents: false,
        liveVideoId: null,
        showLiveVideoIdDialog: false,
        loading: {
            liveVideoId: false
        }
    }),
    mounted() {
        if(localStorage.getItem("isLoggedIn")){
        this.getUser();
        setInterval(() => {
            this.getUser();
        }, 18000000);
        this.listen();
        this.getRecentMessages();
        }
       // alert(this.currentRouteName);
        console.log(location.pathname.replace(/[0-9]/g, ''));
        localStorage.setItem("KMInstallWizFormData", "");
        localStorage.setItem("127001_connect_production", "");
        localStorage.setItem("127001_connect_production_version", "");
        localStorage.removeItem('KMInstallWizFormData');
        localStorage.removeItem('127001_connect_production');
        localStorage.removeItem('127001_connect_production_version');

    },
    destroyed() {
    },
    watch: {
        "user.unread_notification_count"(value) {
            if (this.isMounted && value) {
                const audio = new Audio("/sound/notification.mp3");
                audio.play();
            }
            this.isMounted = true;
            document.title = value ? `(${value}) Net` : "Net";
        }
    },
    methods: {
        handleConnectivityChange (status) {
            if (!status) { this.toast('b-toaster-top-full') }
        },
        toast (toaster, append  =  false) {
            this.$bvToast.toast(`Você está offline.`, {
                title:  `Conexão perdida!`,
                toaster: toaster,
                solid:  true,
                appendToast: append
            })
        },
        async reloadUser() {
            try {
                const res = await api.auth.user();
                this.user = res.data;
            } catch (err) {
                console.log("error", err);
            }
        },

        async getUser() {
            try {
                const res = await api.auth.user();
                this.user = res.data;
                localStorage.setItem("userId", this.user.id);
               // this.updateVideoChatPresence();
               this.updateStatusToOnline(this.user.id);
                //this.startChatBroadcasting();
               // this.startNotificationBroadcasting();
            } catch (err) {
                console.log(err);
                if (this.$route.meta.requiresAuth) {
                    if (err.response.status === 401) {
                        const userId = localStorage.getItem("userId");
                        this.logout(userId);
                    }
                }
                this.user = {};
                localStorage.removeItem("userId");
                localStorage.removeItem("isLoggedIn");
               // location.href = "/login";
            }
        },

        async updateStatusToOnline(userId) {
            await api.user.updateToOnline(userId);
        },

        async logout(userId = this.user.id) {
            this.showChatbox = false;
            this.closeSideNav();
            try {
                await api.user.updateToOffline(userId);
            } finally {
                if (this.user.id) {
                    await api.auth.logout();
                }
                localStorage.removeItem("userId");
                localStorage.removeItem("isLoggedIn");
                location.href = "/login";
                this.$router.push({ name: "Login" });
            }
        },

        closeSideNav() {
            this.showNotifications = false;
            this.showAddressBook = false;
        },

        updateVideoChatPresence() {
            if (!location.href.includes("video-chat")) {
               // this.updateVideoChatAvailability(0);
            }
        },

        async updateVideoChatAvailability(status) {
            await api.user.update(this.user.id, {
                is_videochat_available: status
            });
        },

        listen() {

        },

        startNotificationBroadcasting() {

        },

        startChatBroadcasting() {

        },

        menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                case "profiles":
                    this.goToHome();
                    break;
                case "randomChat":
                    location.href = "/video-chat";
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                case "logout":
                    this.logout();
                    break;
            }
        },

        openChatbox(user) {
            this.userTwo = user;
            this.showChatbox = true;
        },

        async getRecentMessages() {
            const res = await api.message.recent();
            this.recentMessages = res.data;
        },

        logoSrc() {
            return "/images/logo/net_logo.png";
        },

        goToNext() {
            if (this.currentRoute.name === "Home") {
                location.href = "/video-chat";
            } else if (this.currentRoute.name === "VideoChat") {
                location.href = "/"; // use browswer refresh to refresh webcam
            } else {
                this.$router.push({ name: "Home" });
            }
        },

        goToDashboard() {
            location.href = "/dashboard";
        },

        async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = !this.showNotifications;
            await api.notification.markAsRead();
            this.getUser();
        },

        async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = !this.showAddressBook;
        },

        goToHome() {
            location.href = "/";
        },

        goToNewStreamingPage() {
            location.href = "/new-streaming";
        },

        routeTo(route) {
            location.href = `/${route}`;
        },

        openLiveVideoIdDialog() {
            this.showLiveVideoIdDialog = true;
        },

        async updateLiveVideoId() {
            this.loading.liveVideoId = true;
            await api.user.update(this.user.id, {
                live_video_id: this.liveVideoId
            });
            this.loading.liveVideoId = false;
        }
    }
};
</script>
